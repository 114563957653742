<template>
    <div>
        <h3>Compromisos gestionados semanalmente</h3>
        <Bar
          :chart-options="chartOptions"
          :chart-data="chartData"
        />
    </div>
</template>
<script>
    import { Bar } from 'vue-chartjs/legacy'
    import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
    import ProyectoForm from "@/views/formulario/ProyectoForm.vue"
    import ChartDataLabels from 'chartjs-plugin-datalabels';
    ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ChartDataLabels)

    export default {
        name: 'BarChart',
        components: {
            Bar,
            ProyectoForm
        },
        props: {
            label: {
                type: Array,
                required: true
            },
            backgroundColors: {
                type: Array,
                required: true
            },
            datos: {
                type: Array,
                required: true
            },
            indexAxis: {
                type: String,
                default: 'x'
            }
        },
        data() {
            return {
                chartData: {
                    labels: this.label,
                    datasets: [
                        {
                            label: 'Compromisos',
                            backgroundColor: this.backgroundColors, 
                            data: this.datos 
                        }
                    ]
                },
                chartOptions: {
                    indexAxis: this.indexAxis,
                    responsive: true,
                    maintainAspectRatio: false,
                    onClick: (event, elements, chart) => {
                        if (elements.length !== 0) {
                            let indice = elements[0].index;
                            let labelClicado = this.label[indice];
                        }         
                    },
                    plugins: {
                        datalabels: {
                            color: '#000',
                            align: 'end', 
                            formatter: (value, context) => {
                                return ''
                                /* let total = context.dataset.data.reduce((acc, val) => acc + val, 0);
                                if (value === 0){
                                    return ''
                                }
                                let percentage = (value / total) * 100;
                                let roundedPercentage = Math.round(percentage * 100) / 100;
                                return roundedPercentage.toFixed(2) + '% ' + '(' + value + ')'; */
                            }
                        },
                        legend: {
                            display: false,
                        }
                    },
                    scales: {
                        x: {
                            display: true,
                            ticks: {
                                display: true 
                            }
                        },
                        y: {
                            display: true
                        }
                    }
                }
            }
        }
    }
</script>

  