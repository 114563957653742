<template>
    <v-dialog v-model="dialog" max-width="450" :height="height" persistent :width="width">
        <v-card>
            <LoadData ref="load"></LoadData>
            <v-toolbar flat class="dialog-toolbar">
                <v-toolbar-title class="justify-center">{{title}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="cancelDialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-row jusify="center">
                    <v-col cols="12">
                        <div class="text-center">
                            <span>{{question}}</span>
                            <br>
                        </div>
                    </v-col>
                </v-row>
                <v-row jusify="center" v-if="showTable">
                    <v-col cols="12">
                        <v-card>
                            <v-card-title>
                                Lugares visitados
                            </v-card-title>
                            <v-card-text>
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th>
                                                    Lugar
                                                </th>
                                                <th>
                                                    Acciones
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(lugar, index) in lugares">
                                                <td>
                                                    <v-text-field
                                                        class="mt-5"
                                                        dense
                                                        v-model="lugares[index].lugar"
                                                    ></v-text-field>
                                                </td>
                                                <td>
                                                    <v-btn
                                                        fab
                                                        dark
                                                        color="red"
                                                        small
                                                        @click="eliminarLugar(lugar, index)"
                                                    >
                                                        <v-icon dark>
                                                            mdi-minus
                                                        </v-icon>
                                                    </v-btn>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    fab
                                    dark
                                    color="green"
                                    small
                                    @click="agregarLugar"
                                >
                                    <v-icon dark>
                                        mdi-plus
                                    </v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                    <v-col cols="12">
                        <v-card>
                            <v-card-title>
                                Asistentes
                            </v-card-title>
                            <v-card-text>
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th>
                                                    Nombre
                                                </th>
                                                <th>
                                                    Puesto
                                                </th>
                                                <th>
                                                    Acciones
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(asistente, index) in asistentes">
                                                <td>
                                                    <v-text-field
                                                        class="mt-5"
                                                        dense
                                                        v-model="asistentes[index].nombre"
                                                    ></v-text-field>
                                                </td>
                                                <td>
                                                    <v-text-field
                                                        class="mt-5"
                                                        dense
                                                        v-model="asistentes[index].puesto"
                                                    ></v-text-field>
                                                </td>
                                                <td>
                                                    <v-btn
                                                        fab
                                                        dark
                                                        color="red"
                                                        small
                                                        @click="eliminarAsistente(asistente, index)"
                                                    >
                                                        <v-icon dark>
                                                            mdi-minus
                                                        </v-icon>
                                                    </v-btn>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    fab
                                    dark
                                    color="green"
                                    small
                                    @click="agregarAsistente"
                                >
                                    <v-icon dark>
                                        mdi-plus
                                    </v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                    <v-col cols="12">
                        <v-card>
                            <v-card-title>
                                Observaciones
                            </v-card-title>
                            <v-card-text>
                                <text-area-component label="" :rows=3 ref="tfObservacion" :counterValue="5000"></text-area-component>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row v-if="item.auditoria" class="ml-1">
                    <v-checkbox v-model="agregarEstados" label="Agregar Estados de compromisos"></v-checkbox>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-col justify="center">
                    <div class="text-center">
                        <v-btn class="ml-3" color="primary" @click="acceptDialog()">Aceptar</v-btn>
                        <v-btn class="ml-3" color="error" @click="cancelDialog()">Cancelar</v-btn>
                    </div>
                </v-col>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import axios from "axios"
    import LoadData from '@/components/LoadData.vue'
    import { EventBus } from "@/event/event"
    export default {
        props: {
            width: {
                type: Number
            },
            height: {
                type: Number,
                default: 400
            },
            showTable: {
                type: Boolean,
                default: false
            }
        },
        components: {
            LoadData,
        },
        data() {
            return {
                title: '',
                question: '',
                dialog: false,
                view: '',
                item: '',
                lugares: [
                    { lugar: '' }
                ],
                asistentes: [
                    { nombre: '', puesto: '' }
                ],
                agregarEstados: false
            }
        },
        computed: {
            camposLugares() {
                return this.lugares.every(lugar => lugar.lugar);
            },
            camposAsistentes() {
                return this.asistentes.every(asistente => asistente.nombre);
            }
        },
        methods: {
            open(title, question, view, item) {
                this.title = title
                this.question = question
                this.dialog = true
                this.view = view
                this.item = item
            },
            acceptDialog() {
                this.$refs.load.open();
                switch (this.view) {
                    case 'compromiso':
                        this.deleteCompromiso(this.item)
                        break;
                    case 'generar_plan':
                        this.generateReport(this.item)
                        break;
                    case 'generar_acta':
                        this.generarActa(this.item)
                        break;
                    case 'asignacion':
                        this.deleteAsignacion(this.item)
                        break
                    case 'asignacion-titular':
                        this.deleteAsignacionTitular(this.item)
                        break
                    case 'ficha-documentos':
                        this.eliminarDocumento(this.item)
                        break
                    case 'ficha-instrumento':
                        this.eliminarDocumento(this.item)
                        break
                    case 'compromiso-documento':
                        this.eliminarDocumento(this.item)
                        break
                }
            },
            cancelDialog() {
                this.dialog = false
            },
            async deleteAsignacion(item) {
                const data = {
                    proyecto_id: item.proyecto.id,
                    usuario_id: item.usuario.id
                }
                await axios.post('asignaciones/eliminar', data).then(response => {
                    this.afterService(response)
                })
                .catch( error => {
                    console.error(error)
                })
            },
            async deleteAsignacionTitular(item) {
                const data = {
                    titular_id: item.titular.id,
                    usuario_id: item.usuario.id
                }
                await axios.post('asignacion/titular/eliminar', data).then(response => {
                    this.afterService(response)
                })
                .catch( error => {
                    console.error(error)
                })
            },
            async deleteCompromiso(item) {
                await axios.put('/compromisos/inactivar/' + item.compromiso_id)
                .then(response => {
                    this.afterService(response)
                })
                .catch( error => {
                    console.error(error)
                })
            },
            async generateReport(item) {
                this.agendarVisita(item.visita_id)
                await axios({
                    url: '/pdf-report',
                    method: 'GET',
                    params: item
                }).then(response => {
                    EventBus.$emit('open-informe', 'data:application/pdf;base64,'+response.data.body.base64, response.data.body.documento.id, item.nombre)
                    // Cambiar estado Visita
                    const data = {
                        nuevo_estado: 15 //Enviado
                    }
                    axios.put('visita/estado/' + item.visita_id, data)
                    .then((response) => {
                        EventBus.$emit('refresh-table')
                        EventBus.$emit('close-visita')
                        this.$refs.load.close();
                        this.dialog = false
                    }).catch((error) => {
                        console.error('Error al descargar el archivo:', error);
                        EventBus.$emit('error-reporte', error.response)
                        this.$refs.load.close();
                    })
                    this.$refs.load.close();
                }).catch(error => {
                    console.error('Error al descargar el archivo:', error);
                    EventBus.$emit('error-reporte', error.response)
                    this.$refs.load.close();
                });
            },
            afterService(response) {
                this.$refs.load.close();
                this.dialog = false
                if(this.view == 'asignacion' || this.view == 'asignacion-titular') {
                    EventBus.$emit('refresh-asignaciones', response)
                } else if (this.view == 'ficha-documentos' || this.view == 'ficha-instrumento' || this.view == 'compromiso-documento' ){
                    EventBus.$emit('refresh-documentos', response)
                } else {
                    EventBus.$emit('refresh-compromisos', response)
                }
            },
            async generarActa(item) {
                this.$refs.load.open()
                const data = {
                    nuevo_estado: 17, //Finalizado,
                    lugares: this.lugares,
                    asistentes: this.asistentes,
                    observacion: this.$refs.tfObservacion.getValue()
                }
                await axios.put('visita/estado/' + item.visita_id, data).then((response) => {
                    let items = item
                    items.asistentes = this.asistentes
                    items.lugares = this.lugares
                    items.observacion = this.$refs.tfObservacion.getValue()
                    item.agregar_estados = this.agregarEstados
                    axios({
                        url: '/pdf-report',
                        method: 'GET',
                        params: item
                    }).then(response => {
                        EventBus.$emit('open-informe', 'data:application/pdf;base64,' + response.data.body.base64, response.data.body.documento.id, item.nombre)
                        EventBus.$emit('refresh-table')
                        EventBus.$emit('close-ejecutar-visita')
                        this.dialog = false
                    }).catch(error => {
                        console.error('Error al descargar el archivo:', error);
                        EventBus.$emit('error-reporte', error.response)
                        this.$refs.load.close();
                    });
                }).catch((error) =>{
                    console.error('Error al actualizar visita:', error);
                    EventBus.$emit('error-reporte', error.response)
                    this.$refs.load.close();
                })
                
            },
            async agendarVisita(visita_id) {
                const data = {
                    visita_id: visita_id
                };
                await axios.post('agenda', data)
                    .then((response) => {
                }).catch((error) => {
                    console.error(error);
                });
            },
            async eliminarDocumento(item) {
                await axios.put('/documento/eliminar/' + item.id)
                .then(response => {
                    this.afterService(response)
                })
                .catch( error => {
                    console.error(error)
                })
            },
            agregarLugar(){
                if (this.camposLugares) {
                    if (this.lugares.length < 10) {
                        this.lugares.push({lugar: ''})
                    }
                }
            },
            eliminarLugar(valor, index){
                if (this.lugares.length > 1) {
                    this.lugares.splice(index, 1)
                }
            },
            agregarAsistente() {
                if (this.camposAsistentes) {
                    if (this.asistentes.length < 10) {
                        this.asistentes.push({ nombre: '', puesto: '' })
                    }
                }
            },
            eliminarAsistente(valor, index){
                if (this.asistentes.length > 1) {
                    this.asistentes.splice(index, 1)
                }
            }
        }
    }
</script>

<style scoped>
    .dialog-toolbar {
        position: sticky;
        top: 0;
        background-color: white; /* Cambia esto al color que desees */
        z-index: 2;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
</style>