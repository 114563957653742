<template>
    <v-row justify="end" class="mr-1">
        <v-dialog
            v-model="dialog"
            persistent
            width="60%"
        >
            <v-card>
                <LoadData ref="load"></LoadData>
                <v-toolbar flat class="dialog-toolbar">
                    <v-toolbar-title class="text-h5">Cargar Antecedente/Verificador</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="cancelar">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <snackbar-component :snackbar.sync="snackbar" :texto="texto" :estado="estado"></snackbar-component>
                <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation :disabled="disabled">
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <select-component label="Tipo" ref="sTipo" :data="tipo" :valor="selectTipo" itemValue="valor" itemText="text" required @selected-value="mostrarEstado"></select-component>
                                </v-col>
                                <v-col cols="12" class="mt-5">
                                    <v-select :items="tipoAntecedente" v-model="selectTipoAntecedente" label="Tipo antecedente" required dense></v-select>
                                </v-col>
                                <v-col cols="12">
                                    <text-field-component label="Nombre *" ref="tfNombre" :valor="nombre" required></text-field-component>
                                </v-col>
                                <v-col cols="12" class="mt-6" v-if="selectTipoAntecedente == 'Documento'">
                                    <file-input-component label="Cargar documento (Máx 100MB)" :valor="documento" :ref="'documentoRef'" :required="true" @file-selected="nombreDocumento"></file-input-component>
                                </v-col>
                                <v-col cols="12" class="mt-6" v-if="selectTipoAntecedente == 'Link'">
                                    <text-field-component label="Url" :valor="link" ref="tfLink" required></text-field-component>
                                </v-col>
                                <!-- <v-col cols="12" class="mt-6" v-if="mostrarSelectEstado">
                                    <select-component label="Estado" ref="cmbEstados" url="estados/categoria/C" :valor="estadoSelect" clearable></select-component>
                                </v-col> -->
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-btn
                                        color="blue-grey"
                                        class="ma-2 white--text"
                                        @click="cargarDocumento"
                                        :disabled="disabled"
                                        :loading="loading"
                                    >
                                        Cargar
                                        <v-icon>mdi-upload</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                    <v-col cols="12" v-if="mostrarTabla">
                        <table-component class="ml-4" :headers="headers_documentos" :url="url" view="ficha-instrumento" :disabled="disabled" labelSearch="Búsqueda de documentos" :exportExcel="false" :key="tablaDocumento" :showAdd="false"></table-component>
                    </v-col>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="ma-2"
                        large
                        @click="cancelar"
                    >
                        Cerrar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
    import axios from "axios";
    import LoadData from '@/components/LoadData.vue'
    import { EventBus } from "@/event/event"
    import { commonStore } from "@/store/CommonStore";

    export default {
        components: {
            LoadData
        },
        props: {
            mostrarTabla: {
                type: Boolean,
                default: true
            },
        },
        data: () => ({  
            dialog: false,
            valid: true,
            disabled: false,
            item: [],
            documento: null,
            headers_documentos: [
                { text: 'Nombre documento', value: 'nombre', filtro:false },
                { text: 'Fecha creación', value: 'created_at', filtro:false },
                { text: 'Acciones', value: 'actions', filtro:false }
            ],
            tablaDocumento: 0,
            snackbar: false,
            texto: '',
            estado: 0,
            url: '',
            flag: 2 /* flag acta terreno */,
            nombre: '',
            commonStore: commonStore(),
            link: '',
            selectTipoAntecedente: '',
            tipoAntecedente: ['Documento', 'Link'],
            tipo: [
                {valor: 'v', text: 'Verificador', 'id': 1},
                {valor: 'a', text: 'Antecedente', 'id': 2}
            ],
            selectTipo: [],
            mostrarSelectEstado: false,
            estadoSelect: [],
            loading: false
        }),
        methods: {
            async cargarDocumento() {
                this.loading = true
                this.disabled = true
                if (this.$refs.sTipo.getValue() == undefined || !this.$refs.sTipo.getValue().valor) {
                    this.snackbar = true;
                    this.texto = "Debe seleccionar Tipo"
                    this.estado = 500
                    return
                }
                if (!this.selectTipoAntecedente || this.selectTipoAntecedente == '') {
                    this.snackbar = true;
                    this.texto = "Debe seleccionar Tipo antecedente"
                    this.estado = 500
                    return
                }
                if (this.selectTipoAntecedente == 'Documento') {
                    let doc = this.$refs.documentoRef.getValue()
                    let tipo = this.$refs.sTipo.getValue().valor
                    if (doc) {
                        const data = new FormData()
                        const config = {
                            headers: {
                            'Content-Type': 'multipart/form-data',
                            },
                        };
                        data.append('tipoAntecedente', this.selectTipoAntecedente)
                        data.append('documento', doc)
                        data.append('flag', this.flag)
                        data.append('nombre', this.$refs.tfNombre.getValue())
                        data.append('visita_id', this.item.visita_id ? this.item.visita_id : [])
                        data.append('id', this.item.compromiso_id ? this.item.compromiso_id : [])
                        data.append('tipo', tipo)
                        if (this.item.codigo_dss) {
                            data.append('proyecto_id', this.item.id)
                        } else {
                            data.append('proyecto_id', this.item.proyecto_id)
                        }
                        /* if (this.mostrarSelectEstado) {
                            data.append('estado', this.$refs.cmbEstados.id ? this.$refs.cmbEstados.id : [])
                        } */
                        axios.post('/documento/uploadFiles', data, config).then(response => {
                            this.snackbar = true;
                            this.texto = response.data.message
                            this.estado = response.data.code
                            this.$refs.form.reset();
                            this.$refs.form.resetValidation();
                            this.tablaDocumento++
                            if (tipo == 'v') {
                                this.actualizarConfirmacion();
                            }
                            this.$emit("actualiza-documentos");
                            this.loading = false
                            this.disabled = false
                        }).catch(error => {
                            console.error(error)
                            this.snackbar = true;
                            this.texto = error.response.data.message
                            this.estado = error.response.data.code
                        })
                    } else {
                        this.snackbar = true;
                        this.texto = "Debe agregar archivo para guardar"
                        this.estado = 204
                    }
                } else {
                    let link = this.$refs.tfLink.getValue()
                    let tipo = this.$refs.sTipo.getValue().valor
                    if (link) {
                        const data = new FormData()
                        const config = {
                            headers: {
                            'Content-Type': 'multipart/form-data',
                            },
                        };
                        data.append('tipoAntecedente', this.selectTipoAntecedente)
                        data.append('link', link)
                        data.append('flag', this.flag)
                        data.append('nombre', this.$refs.tfNombre.getValue())
                        data.append('visita_id', this.item.visita_id ? this.item.visita_id : [])
                        data.append('id', this.item.compromiso_id ? this.item.compromiso_id : [])
                        data.append('tipo', tipo)
                        if (this.item.codigo_dss) {
                            data.append('proyecto_id', this.item.id)
                        } else {
                            data.append('proyecto_id', this.item.proyecto_id)
                        }
                        axios.post('/documento/uploadFiles', data, config).then(response => {
                            this.snackbar = true;
                            this.texto = response.data.message
                            this.estado = response.data.code
                            this.$refs.form.reset();
                            this.$refs.form.resetValidation();
                            this.tablaDocumento++
                            if (tipo == 'v') {
                                this.actualizarConfirmacion();
                            }
                            this.$emit("actualiza-documentos");
                            this.loading = false
                            this.disabled = false
                        }).catch(error => {
                            console.error(error)
                            this.snackbar = true;
                            this.texto = error.response.data.message
                            this.estado = error.response.data.code
                        })
                    } else {
                        this.snackbar = true;
                        this.texto = "Debe agregar una url para guardar"
                        this.estado = 204
                    }
                }
            },
            cancelar() {
                this.$refs.form.reset();
                this.$refs.form.resetValidation();
                this.dialog = false
            },
            open(item, disabled) {
                if (item.visita_id) {
                    this.url = '/documentos/compromiso/visita/' + item.compromiso_id + '/' + item.visita_id
                    this.flag = this.commonStore.flagAdjunto
                } else if (item.codigo_dss) {
                    this.flag = this.commonStore.flagDss
                } else {
                    this.url = '/documentos/compromiso/'+ item.compromiso_id
                    this.flag = this.commonStore.flagAdjunto
                }
                this.item = item
                this.disabled = disabled
                this.dialog = true
                this.tablaDocumento++
            },
            nombreDocumento(valor) {
                if (this.$refs.tfNombre) {
                    this.$refs.tfNombre.setValue(valor.name.replace(/\.[^/.]+$/, ""))
                }
            },
            mostrarEstado(value) {
                if(value.valor == 'v'){
                    this.mostrarSelectEstado = true
                } else {
                    this.mostrarSelectEstado = false
                }
            },
            async actualizarConfirmacion() {
                const data = {
                    accion: 'v'
                }
                await axios.put('/compromiso/confirmacion/' + this.item.compromiso_id, data).then(response => {
                    if (response.data.message != '') {
                        this.snackbar = true;
                        this.texto = response.data.message
                        this.estado = 204 //response.data.code
                    }
                    //EventBus.$emit('refresh-table')
                }).catch(error => {
                    console.error(error)
                    this.snackbar = true;
                    this.texto = error.response.data.message
                    this.estado = error.response.data.code
                })
            }
        },
        mounted() {
            EventBus.$on('refresh-documentos', (response) => {
                this.tablaDocumento++
                if (response) {
                    this.snackbar = true;
                    this.texto = response.data.message
                    this.estado = response.data.code
                }
            });
        }
    }
</script>

<style scoped>
  .dialog-toolbar {
    position: sticky;
    top: 0;
    background-color: white; /* Cambia esto al color que desees */
    z-index: 2;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
</style>