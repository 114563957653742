<template>
  <div>
    <v-container fluid class="mt-6">
      <div class="ml-6">
        <v-col justify="center" align="center">
          <v-card width="400">
            <v-card-title>Iniciar sesión</v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <v-text-field
                v-model="email"
                label="Correo electrónico"
                hint="Ejemplo: mail@mail.com"
                prepend-icon="mdi-email"
              ></v-text-field>
              <v-text-field
                v-model="password"
                :type="show1 ? 'text' : 'password'"
                name="input-10-1"
                @click:append="show1 = !show1"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                label="Contraseña"
                prepend-icon="mdi-account-key"
                @keyup.enter="authenticate()"
              ></v-text-field>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-col style="padding: 0px" justify="center" align="center">
                <p v-if="valid" class="p-invalid-pass">Usuario o contraseña incorrecta</p>
                <v-btn
                  color="success"
                  @click.prevent="authenticate"
                  :loading="loading"
                  >Iniciar sesión</v-btn
                >
              </v-col>
            </v-card-actions>
          </v-card>
        </v-col>
      </div>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { perfilStore } from "@/store/PerfilStore";
export default {
  data() {
    return {
      loading: false,
      show1: false,
      email: "",
      password: "",
      dialog: false,
      perfilStore: perfilStore(),
      valid: false
    };
  },
  beforeCreate() {
    let access_token = this.$cookies.get("XSRF-TOKEN");
    if (!access_token && this.$route.name != "login") {
      this.$router.push({ name: "login" });
    } else if (access_token) {
      this.$router.push({ name: "carga-datos" });
    }
  },
  methods: {
    authenticate() {
      this.loading = true;
      const params = {
        email: this.email,
        password: this.perfilStore.encryptData(this.password),
      };

      axios
      .post("/login", params)
      .then((response) => {
        localStorage.setItem('token', response.data.token.token);
        localStorage.setItem('id', this.perfilStore.encryptData(response.data.user.id))
        localStorage.setItem('nombre', response.data.user.name)
        localStorage.setItem('perfil', response.data.user.nombre_perfil)
        localStorage.setItem('perfil_id', this.perfilStore.encryptData(response.data.user.perfil_id))
        localStorage.setItem('exp_token', new Date(response.data.exp_token).getTime())
        let fechaExpiracion = new Date(response.data.user.expiration_password);
        let fechaActual = new Date()
        if(fechaActual >= fechaExpiracion) {
          this.$router.push({ name: "cambio-contrasena", query: { alerta: true } }).catch(() => {})
        } else {
          this.$router.push({ name: "home" }).catch(() => {})
        }
      })
      .catch((error) => {
        this.valid = true
        this.loading = false;
        console.error(error);
      });
    },
  },
};
</script>

<style lang="css" scoped>
.primary_bg {
  background-color: #9921e8;
  background-image: linear-gradient(315deg, #9921e8 0%, #5f72be 74%);
}
.v-text-field {
  width: 400px;
}
.p-invalid-pass {
  color: red;
}
</style>
