<template>
    <v-dialog v-model="dialog" max-width="990" :height="height" persistent :width="width">
        <v-card>        
            <v-card-title>
                {{ title }} 
                <v-toolbar flat class="dialog-toolbar">
                    <v-spacer></v-spacer>
                    <v-btn icon @click="cancelar">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
            </v-card-title>
            <v-divider class="mb-6"></v-divider>
            <v-card-text v-if="text">{{ text }}</v-card-text>
            <v-card-text v-if="headers">
                <table-component :headers="headers" :data="data" view="uf-titulares" :key="contador" :showAdd="false" :exportExcel="false" :isSeleccionColumnas="false"></table-component>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        props: {
            width: {
                type: Number,
                default: 999
            },
            height: {
                type: Number,
                default: 600
            }
        },
        data() {
            return {
                title: '',
                text: '',
                dialog: false,
                contador: 0,
                headers: [],
                data: []
            }
        },
        methods: {
            open(title, text, header, data) {
                this.title = title
                this.text = text
                this.headers = header
                this.data = data
                this.dialog = true
            },
            cancelar() {
                this.title = ''
                this.text = ''
                this.headers = ''
                this.data = ''
                this.dialog = false
            }
        }
    }
</script>